.PhoneInput {
  width: 100%;
  height: 38px;
  padding: 12px 16px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  direction: ltr;
  input {
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    padding-inline-start: 10px;

    &:focus-visible {
      outline: 0;
    }
  }
  .PhoneInputCountry {
    gap: 8px;
  }
}
.ant-radio-button:hover {
  background: #eef0ff !important;
  border-radius: 5%;
}
.ant-radio-button-checked {
  background: #eef0ff !important;
  border-radius: 5%;
}
.ant-input-affix-wrapper,
.ant-select-selector,
.ant-picker {
  input {
    &::-webkit-input-placeholder {
      font-size: 14px;
      letter-spacing: -0.28px;
    }

    &:-ms-input-placeholder {
      font-size: 14px;
      letter-spacing: -0.28px;
    }

    &::placeholder {
      font-size: 14px;
      letter-spacing: -0.28px;
    }
  }
}
.ant-input-affix-wrapper-status-error {
  .ant-input-suffix {
    display: none;
  }
}
.ant-input-affix-wrapper-focused {
  &.ant-input-affix-wrapper-status-error {
    .ant-input-suffix {
      display: block;
    }
  }
}
.filterHeadTableSales {
  .ant-radio-inner {
    display: block !important;
  }
}
.ant-radio-inner {
  display: none !important;
}
.ant-radio-wrapper-checked {
  border: 1px solid #00086d !important;
  color: #00086d !important;
}
.ant-radio-wrapper-checked span {
  color: #00086d !important;
  font-weight: 500;
}
.ant-dropdown-menu-item-only-child {
  background: white !important;
}
