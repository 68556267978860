@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

:root {
  --color-primary: #00086d;
  --color-display: #00b2f3;
  --color-secondary: #005a7b;
  --color-bg: #f5fcff;
  --color-bg-2: #ebfaff;
  --color-gray: #828385;
  --color-gray-light: #f6f6f6;
  --color-success: #4bb543;
  --color-warn: #faad14;
  --color-danger: #ff4d4f;
  --color-light: #eef0ff;
  --color-border-button: #00086d;
  --color-light-gr: #fbfbff;
  --font-primary: Cairo, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, "Cairo", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  --swiper-theme-color: #001a62 !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./form";
@import "./antd";
@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';

html,
body {
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  font-family: "Helvetica Neue", Helvetica, Tahoma, Arial, sans-serif;
}

img,
video {
  max-width: none;
  height: auto;
}

.responsive-logo {
  width: 240px !important;
}

@media (max-width: 640px) {
  .responsive-logo {
    width: 150px !important;
  }
}

.ant-btn-default:disabled,
.ant-btn-default.ant-btn-disabled {
  cursor: not-allowed;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  box-shadow: none;
}