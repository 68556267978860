.ant-layout-header {
  height: 64px;
  padding: 0 50px;
  color: rgba(0, 0, 0, 0.88);
  line-height: 64px;
  background: #fff !important;
}

.ant-btn-default {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.88);
  box-shadow: none;
}

.ant-btn-primary {
  color: #fff;
  background: #1677ff;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}

.ant-menu-light.ant-menu-horizontal>.ant-menu-item-selected::after,
.ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-item-selected::after,
.ant-menu-light.ant-menu-horizontal>.ant-menu-submenu-selected::after,
.ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-submenu-selected::after {
  border-bottom-width: 0px;
  border-bottom-color: transparent;
  display: none;
}

.ant-menu-light.ant-menu-horizontal>.ant-menu-item::after,
.ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-item::after,
.ant-menu-light.ant-menu-horizontal>.ant-menu-submenu::after,
.ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-submenu::after {
  position: absolute;
  inset-inline: 16px;
  bottom: 0;
  border-bottom: 2px solid transparent;
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: "";
  display: none;
}

.ant-menu::before {
  display: table;
  content: "";
  display: none !important;
}

.ant-menu-horizontal::after {
  display: block !important;
  clear: both;
  height: 0;
  content: "\20";

}

.ant-menu-horizontal {
  line-height: 46px;
  border: 0;
  border-bottom: 0px;
  box-shadow: none;
}

.header .ant-layout-header .css-dev-only-do-not-override-98ntnt {
  background-color: rgba(0, 123, 255, 0.4);
}

.ant-menu-light .ant-menu-item-selected,
.ant-menu-light>.ant-menu .ant-menu-item-selected {
  background-color: transparent;
}

.ant-menu-light .ant-menu-item-selected,
.ant-menu-light>.ant-menu .ant-menu-item-selected {
  color: black;
}

.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu {
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  padding-inline: 12px;
}

h2.ant-typography, div.ant-typography-h2, div.ant-typography-h2>textarea, .ant-typography h2 {
  margin-bottom: 0.2em;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  font-size: 30px;
  line-height: 1.2666666666666666;
}

.ant-btn {
  gap: 4px;
}

.ant-space-gap-col-small {
  column-gap: 0px;
}

@media (max-width: 575px) {
  .ant-menu-horizontal .ant-menu-item,
  .ant-menu-horizontal .ant-menu-submenu {
    position: relative;
    display: inline-block;
    vertical-align: bottom;
    padding-inline: 4px !important;
  }

  .responsive-icon {
    font-size: 18px !important;
  }
}
